import axios from "axios";
import { createStore } from "vuex";

import { getLocalUser } from "../helpers/auth";

const user = getLocalUser();
// const user = 98;

export default createStore({
  state: {
    welcomeMessage: "Welcome to the DinHMS admin",
    currentUser: user,
    checkout: [],
    isLoggedIn: !!user,
    loading: false,
    auth_error: null,
    customers: [],
    customersz: [],
    persons: [],
    language: [],
    lang: "en",
    contentTemplate: [
      { val: "home.index", text: "home.index" },
      { val: "home.content", text: "home.content" },
      { val: "home.contact", text: "home.contact" },
      { val: "login", text: "Login" },
      { val: "home.product", text: "home.product" },
      { val: "home.Tjenester", text: "home.Tjenester" },
      { val: "order.listorder", text: "order.cistorder" },
      { val: "order.createorder", text: "order.createorder" },
    ],
    companiClassification: [
      { val: "1", text: "Coach" },
      { val: "2", text: "Internal" },
      { val: "3", text: "Sport-clubs" },
      { val: "4", text: "Family" },
      { val: "6", text: "Business" },
    ],
    companiType: [
      { val: "1", text: "Supplier manager" },
      { val: "2", text: "Supplier Terapeut" },
      { val: "3", text: "Client manager" },
      { val: "4", text: "Client employee" },
    ],
    monthList: [
      { val: "1", text: "January", langstring: "idJanuary" },
      { val: "2", text: "February", langstring: "idFebruary" },
      { val: "3", text: "Mars", langstring: "idMarch" },
      { val: "4", text: "April", langstring: "idApril" },
      { val: "5", text: "Mai", langstring: "idMay" },
      { val: "6", text: "Juni", langstring: "idJune" },
      { val: "7", text: "Juli", langstring: "idJuly" },
      { val: "8", text: "August", langstring: "idAugust" },
      { val: "9", text: "September", langstring: "idSeptember" },
      { val: "10", text: "Oktober", langstring: "idOctober" },
      { val: "11", text: "November", langstring: "idNovember" },
      { val: "12", text: "Desember", langstring: "idDecember" },
    ],
    budget: [
      { value: 1, text: "$0-$25.000" },
      { value: 2, text: "$25.000-$50.000" },
      { value: 3, text: "$25.000-$100 000" },
      { value: 4, text: "$100.000-$250.000" },
      { value: 5, text: "$250.000-$500.000" },
      { value: 6, text: "$500.000-$1.000.000" },
      { value: 7, text: "$1.000.000-$2.500.000" },
      { value: 8, text: "$2.500.000-$5.000.000" },
      { value: 9, text: "$5.000.000-$10.000.000" },
      { value: 10, text: "$10.000.000-$25.000.000" },
      { value: 11, text: "$25.000.000+" },
    ],
    prioritas: [
      { value: 1, text: "High" },
      { value: 2, text: "Medium" },
      { value: 3, text: "Low" },
    ],
    counter: 0,
    history: [0],
    cart:[],
    totalAmount: 0,
    totalVAT: 0,
    finalAmount: 0,
    totalWeight: 0,
    totalHeight: 0,
    totalWidth: 0,
    totalLength: 0,
    PKPNumber:null,
  },

  mutations: {
    login(state) {
      (state.loading = true), (state.auth_error = null);
    },
    addToCounter(state, payload) {
      state.counter += payload;
      state.history.push(state.counter);
    },
    subtractFromCounter(state, payload) {
      state.counter -= payload;
      state.history.push(state.counter);
    },
    loginSuccess(state, payload) {
      state.auth_error = null;
      state.isLoggedIn = true;
      state.loading = false;
      state.currentUser = Object.assign({}, payload.user, {
        token: payload.access_token,
      });

      localStorage.setItem("user", JSON.stringify(state.currentUser));
    },
    loginFailed(state, payload) {
      (state.loading = false), (state.auth_error = payload.error);
    },
    logout(state) {
      localStorage.removeItem("user");
      state.isLoggedIn = false;
      state.currentUser = null;
    },
    updateCustomers(state, payload) {
      state.customersz = payload;
    },

    listPerson(state, payload) {
      state.persons = payload;
    },
    language: (state, newValue) => {
      state.language = newValue;
    },
    setPaymentContact(state) {
      state.currentUser.payment_contact = 1;

      let newValue = {
        ...JSON.parse(localStorage.getItem("user")),
        payment_contact: 1,
      };
      localStorage.setItem("user", JSON.stringify(newValue));
    },
    setPaymentMedia(state) {
      state.currentUser.payment_media = 1;

      let newValue = {
        ...JSON.parse(localStorage.getItem("user")),
        payment_media: 1,
      };
      localStorage.setItem("user", JSON.stringify(newValue));
    },
    setPaymentRegion(state) {
      state.currentUser.payment_region = 1;

      let newValue = {
        ...JSON.parse(localStorage.getItem("user")),
        payment_region: 1,
      };
      localStorage.setItem("user", JSON.stringify(newValue));
    },
    setProfileImg(state, payload) {
      state.currentUser.profile_img = payload;

      let newValue = {
        ...JSON.parse(localStorage.getItem("user")),
        profile_img: payload,
      };
      localStorage.setItem("user", JSON.stringify(newValue));
    },
    updateUser(state, payload) {
      state.currentUser.first_name = payload.first_name;
      state.currentUser.last_name = payload.last_name;
      state.currentUser.name = payload.name;

      let newValue = {
        ...JSON.parse(localStorage.getItem("user")),
        first_name: payload.first_name,
        last_name: payload.last_name,
        name: payload.name,
      };
      localStorage.setItem("user", JSON.stringify(newValue));
    },

    checkout(state, payload) {
      state.lang = payload;
    },
    setLang(state, payload) {
      state.lang = payload;
    },

    setPKPNumber(state, payload) {
      state.PKPNumber = payload;
    },

    plusCart(state, payload) {
      let product = {
        id : payload.id,
        Costprice : payload.Costprice,
        Custprice : payload.Custprice,
        ProductCurrency : payload.ProductCurrency,
        ProductName : payload.ProductName,
        UnitCostPrice : payload.UnitCostPrice,
        UnitCustPrice : payload.UnitCustPrice,
        images : payload.images,
        quantity : payload.quantity,
        color : payload?.color ? payload.color : null,
        size : payload?.size ? payload.size : null,
        VatID : payload.VatID,
        ProductWeight : payload.ProductWeight,
        ProductLength : payload.ProductLength,
        ProductWidth : payload.ProductWidth,
        ProductHeight : payload.ProductHeight
      }

      let check_same_product = false

      for(let i = 0; i < state.cart.length; i++) {
        if(state.cart[i].id == product.id){
          let check_size = true
          if(product.size != null || state.cart[i].size != null){
            if(state.cart[i].size.id == product.size.id){
              check_size = true
            }else{
              check_size = false
            }
          }

          let check_color = true
          if(product.color != null || state.cart[i].color != null){
            if(state.cart[i].color.id == product.color.id){
              check_color = true
            }else{
              check_color = false
            }
          }

          if(check_color && check_size){
            check_same_product = true
            state.cart[i].quantity = state.cart[i].quantity + product.quantity
          }
        }
      }

      if(check_same_product == false){
        state.cart.push(product)
      }
      
      let amount = parseFloat(product.Custprice.replace(/,/g, ''))*parseInt(product.quantity)
      let calc = (product.VatID+100)/100
      let before_vat_amount = amount/calc
      let vat_amount = amount - before_vat_amount

      state.totalAmount = state.totalAmount+before_vat_amount
      state.totalVAT = parseFloat(state.totalVAT)+parseFloat(vat_amount.toFixed(2))
      state.finalAmount = state.finalAmount+amount

      state.totalWeight = state.totalWeight+(payload.quantity*payload.ProductWeight)
      
      if(state.totalHeight < payload.ProductHeight){
        state.totalHeight = payload.ProductHeight
      }
      
      if(state.totalWidth < payload.ProductWidth){
        state.totalWidth = payload.ProductWidth
      }

      if(state.totalLength < payload.ProductLength){
        state.totalLength = payload.ProductLength
      }
    },

    minusCart(state, index) {
      let product = state.cart[index]

      // state.totalAmount = state.totalAmount-(parseInt(product.Custprice)*parseInt(product.quantity))

      let amount = parseFloat(product.Custprice.replace(/,/g, ''))*parseInt(product.quantity)
      // let vat_amount = amount*product.VatID/100

      // state.totalAmount = state.totalAmount-amount
      // state.totalVAT = state.totalVAT-vat_amount
      // state.finalAmount = state.finalAmount-amount-vat_amount

      let calc = (product.VatID+100)/100
      let before_vat_amount = amount/calc
      let vat_amount = amount - before_vat_amount

      state.totalAmount = state.totalAmount-before_vat_amount
      state.totalVAT = state.totalVAT-vat_amount
      if(state.totalVAT < 0){
        state.totalVAT = 0
      }
      state.finalAmount = state.finalAmount-amount

      state.totalWeight = state.totalWeight-(product.quantity*product.ProductWeight)

      state.cart.splice(index, 1)

      state.totalHeight = 0
      state.totalWidth = 0
      state.totalLength = 0

      for(let i = 0; i < state.cart.length ; i++){
        if(state.totalHeight < state.cart[i].ProductHeight){
          state.totalHeight = state.cart[i].ProductHeight
        }
        if(state.totalWidth < state.cart[i].ProductWidth){
          state.totalWidth = state.cart[i].ProductWidth
        }
        if(state.totalLength < state.cart[i].ProductLength){
          state.totalLength = state.cart[i].ProductLength
        }
      }
      
      alert("totalHeight", state.totalHeight)
      alert("totalWidth", state.totalWidth)
      alert("totalLength", state.totalLength)
    },
  },

  getters: {
    welcome(state) {
      return state.welcomeMessage;
    },

    templates(state) {
      return state.contentTemplate;
    },

    getPKPNumber(state) {
      return state.PKPNumber;
    },

    isLoading(state) {
      return state.loading;
    },

    isLoggedIn(state) {
      return state.isLoggedIn;
    },

    currentUser(state) {
      return state.currentUser;
    },

    checkout(state) {
      return state.checkout;
    },

    authError(state) {
      return state.auth_error;
    },

    customers(state) {
      return state.customersz;
    },

    persons(state) {
      return state.persons;
    },

    companyClass(state) {
      return state.companiClassification;
    },

    companyType(state) {
      return state.companiType;
    },

    monthList(state) {
      return state.monthList;
    },

    budget(state) {
      return state.budget;
    },

    prioritas(state) {
      return state.prioritas;
    },

    languages(state) {
      return state.language;
    },

    getLang(state) {
      return state.lang;
    },

    cart(state) {
      return state.cart;
    },
    
    totalAmount(state) {
      return state.totalAmount;
    },

    totalVAT(state) {
      return state.totalVAT;
    },

    finalAmount(state) {
      return state.finalAmount;
    },

    totalWeight(state) {
      return state.totalWeight;
    },
    
    totalHeight(state) {
      return state.totalHeight;
    },
    
    totalWidth(state) {
      return state.totalWidth;
    },
    
    totalLength(state) {
      return state.totalLength;
    },

  },
  actions: {
    addToCart({ commit }, payload) {
      commit("plusCart", payload);
    },
    
    removeProduct({ commit }, index) {
      commit("minusCart", index);
    },
    savePKPNumber({ commit }, payload) {
      commit("setPKPNumber", payload);
    },
    login(context) {
      context.commit("login");
    },
    async addRandomNumber(context) {
      let data = await axios.get("https://www.admin.tourdeice.bike/api/hitung");
      context.commit("addToCounter", data.data);
    },
    getDataCompany({ commit }, newValue) {
      commit("language", newValue);
      var data = axios
        .get("https://www.admin.tourdeice.bike/api/company/detail/" + newValue)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          alert("Error");
          console.log(error);
          this.mute = false;
        });

      return data;
    },
    getNodes({ commit, state }, newValue) {
      commit("language", newValue);
      // console.log(newValue, "newValue")
      let lang = state.lang;
      var data = axios
        .get(
          "https://www.admin.tourdeice.bike/api/front/childnode/" +
            newValue +
            "?lang=" +
            lang
        )
        .then((response) => {
          return response.data.data;
        })
        .catch((error) => {
          alert("Error");
          console.log(error);
          this.mute = false;
        });

      return data;
    },
    getNodeById({ commit }, newValue) {
      commit("language", newValue);
      var data = axios
        .get("https://www.admin.tourdeice.bike/api/front/node/" + newValue)
        .then((response) => {
          return response.data.data;
        })
        .catch((error) => {
          alert("Error");
          console.log(error);
          this.mute = false;
        });
      return data;
    },
    
    getProductByNodeId({ commit }, newValue) {
      commit("language", newValue);
      var data = axios
        .get("https://www.admin.tourdeice.bike/api/front/product-by-node/" + newValue)
        .then((response) => {
          return response.data.data;
        })
        .catch((error) => {
          alert("Error");
          console.log(error);
          this.mute = false;
        });
      return data;
    },
    languages: ({ commit }, newValue) => {
      // console.log(newValue);
      commit("language", newValue.sess);
      var dataa = axios
        .post("https://www.admin.tourdeice.bike/api/languagestring/arr/" + newValue.sess, {
          language_string: newValue,
        })
        .then((response) => {
          return response.data.language;
        })
        .catch((error) => {
          alert("Error");
          console.log(error);
          this.mute = false;
        });
      return dataa;
    },
  },
});

// export default store

// export default createStore({
//     state: {
//     },
//     mutations: {
//     },
//     actions: {
//     },
//     modules: {
//     }
// })
