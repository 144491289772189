<template>
    <div class="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="registerModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="cy_sign_img">
                            <img src="/img/signup.jpg" alt="popup" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="cy_sign_form text-left">
                            <form @submit.prevent="register()">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">x</span>
                                </button>

                                <h1>Register</h1>

                                <p><i>* is required</i></p>
                                <br/>

                                <label>*Email</label>
                                <input type="email" v-model="form.email" placeholder="Email..." class="form-control" required>

                                <label>*Password</label>
                                <input type="password" v-model="form.password" placeholder="Password..." class="form-control" required>

                                <label>*First Name</label>
                                <input type="text" v-model="form.first_name" placeholder="First Name..." class="form-control" required>

                                <label>*Last Name</label>
                                <input type="text" v-model="form.last_name" placeholder="Last Name..." class="form-control" required>

                                <label>*Gender</label>
                                <select class="form-control" v-model="form.gender">
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                </select>
                                
                                <label>Your Strava ID, EX : <i>https://www.strava.com/athletes/35106340</i></label>
                                <input type="text" v-model="form.strava_id" placeholder="Strava ID..." class="form-control">

                                <label>*Birthday Year</label>
                                <input type="number" v-model="form.birth_date" min="1900" :max="new Date().getFullYear()" step="1" class="form-control" required>

                                <center v-show="spinner == false"><button type="submit" class="cy_button">Register your interest</button></center>
                                <center v-show="spinner == true"><div class="spinner"></div></center>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>

        </div>
    </div>
    
    <vue-basic-alert 
        :duration="500" 
        :closeIn="3000"
    ref="alert" />
</template>


<script>
export default {
	name: 'register-modal',
	data() {
		return {
            spinner:false,
            form:{
                birth_date: new Date().getFullYear(),
                strava_id:'',
                gender: 'M'
            }
		}
	},
	methods: {
        register(){
            this.spinner = true
            this.form.birth_date = this.form.birth_date+'-01-01'
			this.axios.post("new/register",this.form)
            .then((response) => {
                this.spinner = false

                let type_alert = 'success'
                let text_alert = 'Success'
                if(response.data.error == 1){
                    type_alert = 'error'
                    text_alert = 'Error'
                }

                this.$refs.alert.showAlert(
                    type_alert,
                    response.data.msg,
                    text_alert
                )

                this.form = {
                    birth_date: new Date().getFullYear(),
                    strava_id:'',
                    gender: 'M'
                }
                
                document.getElementById('registerModal').click()
            })
            .catch((error) => {
                console.log(error)
                this.$refs.alert.showAlert(
                    'error',
                    'Something is wrong',
                    'Error'
                )
                this.spinner = false
            })
        }
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>