<template>

	<!--Banner section start-->
	<div class="cy_bread_wrapper">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">{{ product.ProductName }}</h1>
				</div>
			</div>
		</div>
	</div>

    <!-- shop section start -->
    <div class="cy_shop_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="cy_shop_single_page">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">

                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li v-for="(img,index) in product.images" :key="'indicator'+index" data-target="#carouselExampleIndicators" :data-slide-to="index" :class="index == 0 ? 'active' : ''"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div v-for="(img,index) in product.images" :class="index == 0 ? 'carousel-item active' : 'carousel-item'" :key="'img'+index">
                                            <v-lazy-image class="d-block w-100" :src="img" alt="" />
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="summary entry-summary pt-2">
                                    <h1 class="product_title">{{ product.ProductName }}</h1>
                                    <h4 class="product-price-amount">{{product.ProductCurrency}}{{product.Custprice}}</h4>
                                    <p class="woocommerce-product-detail" v-html="product.SalesText"></p>
                                    <div class="quantity" v-if="!currentUser">
                                        <button type="button" data-toggle="modal" data-target="#registerModal" class="cy_button">
                                            <i class="fa fa-check-circle"></i> Register your interest
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 pt-5">
                                <div class="woocommerce-tabs">
                                    <ul class="tabs">
                                        <li class="nav-item">
                                            <a class="nav-link active" href="#discription"  data-toggle="tab">{{ reslang['idDescription'] }}</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <div role="tabpanel" class="tab-pane fade show active" id="discription">
                                            <h3>{{ reslang['idDescription'] }}</h3>
                                            <p class="woocommerce-product-detail" v-html="product.ProductText"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <router-link to="/register" class="cy_button">{{ reslang['idBackToListPage'] }}</router-link>
                </div>
                
            </div>
        </div>

        <!-- Modal -->
        <RegisterModal/>

        <!-- <div class="modal show" id="signup" style="display: block;">
            <div class="cy_signup">
                
            </div>
        </div> -->
    </div>
</template>

<script>
import RegisterModal from './components/RegisterModal.vue';

export default {
	name: 'product-content',
	components: { RegisterModal},
	data() {
		return {
			language_strings : ['idSize', 'idColor', 'idQty','idRequestPrice', 'idDescription',
                'idBackToListPage'
            ],
			reslang: [],
			product: {
                images:[],
                ProductName:'',
                ProductCurrency:'',
                Custprice:'',
                TeaserText:'',
                SalesText:'',
                ProductText:''
            },
			base: 'https://www.admin.tourdeice.bike',
			loading: true,
            mute:false,
		}
	},
	mounted() {
        if (!this.$storage) {
			this.$storage.setStorageSync('langSessionID', "no")
		}
		if(this.$storage.getStorageSync('langSessionID') == undefined){
			this.$storage.setStorageSync('langSessionID', "no")
		}

		this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
		var datalangs = this.$store.dispatch('languages', this.language_strings)

		datalangs.then(response => {
			this.reslang = response
		})
		.catch((error) => {
			console.log(error, 'Got nothing for this user except bad news.')
		})

		this.getdata();
	},
	methods: {
		async getdata() {
			
			window.scrollTo(0, 0)

            this.mute = true

            let lang_id = this.$storage.getStorageSync('langSessionID')

			this.axios.get("get-product/"+this.$route.params.id+"?lang_id="+lang_id)
            .then((response) => {
                this.product = response.data.data
                this.mute = false
            })
            .catch((error) => {
                console.log(error)
                this.mute = false
                this.$router.push({ name: 'NotFound' })
            })
		},
	},
    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>