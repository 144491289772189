import { setAuthorization } from "./general";
import axios from "axios";

export function login(credentials) {
  return new Promise((res, rej) => {
    axios
      .post("https://www.admin.tourdeice.bike/api/auth/login", credentials)
      .then((response) => {
        if (response.data.error != 0) {
          rej(response.data.error);
        } else {
          if (response.data.user.active === 2) {
            rej("your account is inactive");
          } else {
            setAuthorization(response.data.access_token);
            res(response.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });
}


export function forgotPassword(credentials) {
  return new Promise((res, rej) => {
    axios
      .post("https://www.admin.tourdeice.bike/api/auth/forgot_password", credentials)
      .then((response) => {
        if (response.data.error != 0) {
          rej(response.data);
        } else {
          res(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });
}

export function getLocalUser() {
  const userStr = localStorage.getItem("user");

  if (!userStr) {
    return null;
  }

  return JSON.parse(userStr);
}
